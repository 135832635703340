import axios from 'axios'
import env from  '../envioments'
import { Subject } from 'rxjs';

export  class RealTime {

  NewConsultationsubject;

  constructor()
  {
    this.NewConsultationsubject = new Subject();
  }

  NotResponseMessages()
  {
    return axios.get(`${env.remoteUrl}/admin/RealTime/NotResponseMessages`);
  }

  TimeMessage()
  {
    return axios.get(`${env.remoteUrl}/admin/RealTime/TimeMessage`);
  }


}




const NewConsultationsubject = new Subject();

const NotificationService = {

    Notificate: message => NewConsultationsubject.next({ ok : message }),
    clearMessages: () => NewConsultationsubject.next(),
    getNotificate: () => NewConsultationsubject.asObservable()
};


export { NotificationService };
export default new RealTime();
