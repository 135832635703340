<template>
	<div class="main" >
		<b-container class="my-auto">
			<b-row align-h="center">
				<b-col lg="6">
					<Panel header="Login" class="m-auto ">
						<validation-observer ref="observer" v-slot="{ handleSubmit }">
							<b-form @submit.stop.prevent="handleSubmit(onSubmit)">
								<div class="m-auto card-body">
									<b-container>
										<b-row class="pb-3">
											<b-col >
												<div class="form-group">
													<ValidationProvider name="usuario" :rules="{ required: true}"
																		v-slot="validationContext" >
														<div class="p-float-label">
															<InputText id="username" type="text" v-model="model.email"  class="w-100"/>
															<label for="username">Usuario</label>

															<b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

														</div>

													</ValidationProvider>
												</div>


											</b-col>
										</b-row>
										<b-row class="pb-3">
											<b-col >
												<div class="form-group">
													<ValidationProvider name="clave" :rules="{ required: true}" v-slot="validationContext" >
														<div class="p-float-label">
															<InputText id="password" type="password" v-model="model.password" class="w-100" />
															<label for="password">Contraseña</label>

															<b-form-invalid-feedback   :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

														</div>
													</ValidationProvider>
												</div>
											</b-col>
										</b-row>
										<b-row>
											<b-col class="text-right">
												<Button label="Recuperar usuario" type="button" class="p-button-raised p-button-rounded btn-company mx-2"   @click="showRecoveryForm()"/>
												<Button label="Enviar" class="p-button-raised p-button-rounded btn-company" :disabled="is_loading" />
											</b-col>
										</b-row>
									</b-container>
								</div>
							</b-form>
						</validation-observer>
					</Panel>
				</b-col>
			</b-row>
		</b-container>

		<SendRecoveryEmail :show-form="showSendRecovery" ref="sendModal" @hide="showSendRecovery = false;"></SendRecoveryEmail>
	</div>

</template>

<script>

	import InputText from 'primevue/inputtext';
	import Panel from 'primevue/panel';
	import { BFormInvalidFeedback } from 'bootstrap-vue'
	import SendRecoveryEmail from "./SendRecoveryEmail";
	export default {
		data() {
			return {
				is_loading:false,
				showSendRecovery:false,
				model:{
					email: '',
					password:''
				}
			}
		},
		components:{
			InputText,
			Panel,
			BFormInvalidFeedback,
			SendRecoveryEmail
		},
		mounted() {
			// console.log(this.$refs)
		},
		methods :{
			onSubmit() {
				this.is_loading = true;
				this.$store.dispatch('authenticate', this.model)
						.then(()=>{this.is_loading = false;}, ()=> this.is_loading = false)
			},
			showRecoveryForm() {
				return this.showSendRecovery = true;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../assets/layout/variables";
	.main{
		width: 100vw;
		height: 100vh;
		display: flex;
	}

</style>
