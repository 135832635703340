import auth from '../service/Auth';
export default {
    state: {
        user: localStorage.getItem('xser')
            ? JSON.parse(atob(localStorage.getItem('xser')))
            : null
    },
    mutations: {
        login(state, payload) {
            state.user = payload;
            localStorage.setItem('xser', btoa(JSON.stringify(payload)));
        },
        logout(state){
            state.user = null;
            localStorage.removeItem('xser');
        }
    },
    actions: {
        authenticate({commit} , payload){
            return new Promise((resolve, reject) => {
                auth
                    .login(payload.email, payload.password)
                    .then((res) =>{
                        commit('login', Object.assign({},payload, res))
                        resolve(res);
                    },(res)=> {
                        reject(res);
                    })
                ;
            })

        },
        desAuthenticate({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                auth.logout().then(()=>{ })
                resolve();
            })

        }
    },
    getters: {
        isAuthenticate: state => {
            return !!state.user
        }
    }
};
