import {LoadingService} from '../layouts/loading'

export function onFillQualify(config) {
    if (!config.url.includes("api/v1/admin/RealTime"))
    {
      LoadingService.sendMessage("visible");
    }

    if(window.vuex.getters.isAuthenticate) {
        config.headers['Authorization'] = 'Bearer ' +  window.vuex.state.auth.user.token;
    }
    return config;

}
