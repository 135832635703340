<template>
    <div class="layout-profile">
        <button class="p-link layout-profile-link" @click="onClick">
            <span class="username">Administrador</span>
            <i class="pi pi-fw pi-cog"></i>
        </button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link"><i class="pi pi-fw pi-user"></i><span>Account</span></button></li>
                <li v-on:click="$store.dispatch('desAuthenticate')"><button class="p-link"><i class="pi pi-fw pi-power-off" ></i><span>Salir</span></button></li>
            </ul>
        </transition>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                expanded: false
            }
        },
        methods: {
            onClick(event){
                this.expanded = !this.expanded;
                event.preventDefault();
            },
            logout(){
                // console.log(this);
                this.$store.dispatch('desAuthenticate');
            }
        }
    }
</script>

<style scoped>

</style>
