import axios from 'axios'
import env from  '../envioments'
export  class Auth {
    login(email, password ) {
        return axios.post(`${env.remoteUrl}/admin/auth/login`, {email,password })
            .then(res => res.data.data);
    }
    logout( ) {
        return axios.delete(`${env.remoteUrl}/admin/auth/login`, {headers: {
                Authorization: `Bearer ${window.vuex.state.auth.user.token}`
            }})
            .then(res => res.data.data);
    }
    changePassword(token,  data ) {
        return axios.post(`${env.remoteUrl}/admin/auth/change-password/${token}`, data)
            .then(res => res.data.data);
    }
    sendRecovery(data ) {
        return axios.post(`${env.remoteUrl}/admin/auth/recovery-password`, data)
            .then(res => res.data.data);
    }
    getMenu()
    {
      return axios.get(`${env.remoteUrl}/admin/auth/menu`);
    }
}

export default new Auth();
