import axios from 'axios'
import env from  '../envioments'
import { Subject } from 'rxjs';

export  class Utils {

  menu()
  {
    return axios.get(`${env.remoteUrl}/admin/utils/menu`);
  }

  setlocalMenu(data)
  {


    localStorage.setItem('menus',JSON.stringify(data));

  }

  allowMenu(url)
  {

    var menus = localStorage.getItem('menus');
    menus = JSON.parse(menus)
    for (let index = 0; index < menus.length; index++) {
      if(menus[index].to.includes(url))
      {
        return true;
      }
    }
    return false;
  }
}

const MenuLoaded = new Subject();

const MenuLoadedService = {

    Notificate: allowed => MenuLoaded.next({ allow : allowed }),
    clearMessages: () => MenuLoaded.next(),
    getNotificate: () => MenuLoaded.asObservable()
};

export { MenuLoadedService }
export default new Utils();
