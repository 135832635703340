import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import es from 'vee-validate/dist/locale/es.json';

import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
localize("es", es);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
