import {LoadingService} from '../layouts/loading'

export function onFailedResponse(response) {

    if (!response.config.url.includes("api/v1/admin/RealTime"))
    {
      LoadingService.sendMessage("no-visible");
    }

    if(response.response.status === 401){
        window.vuex.commit('logout')
    }
     window.vuex.dispatch('responseError', response.response.data);
    throw response.response;
}

export function onSuccessResponse(response) {

  if (!response.config.url.includes("api/v1/admin/RealTime"))
  {
    LoadingService.sendMessage("no-visible");
  }

    return response;
}
