<template>
  <div class="layout-topbar">
		<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>
		<div class="layout-topbar-icons">
      <span style="display:inline-block; zoom:80%">
        <span v-if ="allowed" type="button" class="icon-button" title="Notificaciones">
          <router-link to="/consultations">
            <span  class="material-icons" >
              <i class="pi pi-bell" style="font-size: 2rem"></i>
            </span>
            <!-- <span class="material-icons">notifications</span> -->
            <span v-if="messages>0" class="icon-button__badge">{{messages}}</span>
          </router-link>
        </span>
          <span type="button" class="icon-button" title="Cerrar Sesión" v-on:click="$store.dispatch('desAuthenticate')">
            <span class="material-icons">
              <i class="pi pi-sign-out" style="font-size: 2rem"></i>

            </span>
          </span>
      </span>



		</div>
	</div>
</template>

<script>

import RealTime , {NotificationService} from '../../service/RealTime';
import utils, {MenuLoadedService} from '../../service/Utils';
import {routesNames} from '../../constants/routes'
// import {} from '../../../public/firebase-messaging-sw';
// import {messaging,firebase} from '../../../firebase-messaging-sw'


export default {
    data()
    {
      return {
        messages: 0,
        time: 0,
        allowed: false
      }
    },
    created() {
      this.$moment.locale('es');

      MenuLoadedService.getNotificate().subscribe(m => {
        if (m.allow)
        {
          if(utils.allowMenu(routesNames.consultations.path))
          {
            this.allowed = true;
            this.getTimeMessage();
          }
        }

      });


      NotificationService.getNotificate().subscribe(m => {
        if (m.ok)
        {

            this.getCountNotResponse();


        }
        // console.log("nueva notificacion")

      });

    //       messaging.getToken().then((currentToken) => {
    //     // console.log('getToken');
    //     if (currentToken)
    //     {
    //         // console.log('getToken success');

    //     }
    //     else
    //     {
    //         // console.log('getToken failure');
    //         // Show permission request.
    //         $('#pushLabel').text('No Instance ID token available. Request permission to generate one.');
    //     }
    // }).catch((err) => {
    //     // console.log('getToken error: ' + err);
    // });



//     messaging.onBackgroundMessage((payload) => {
//       // console.log("pochito")
//   // console.log('Message received. ', payload);
//   // ...
// });


    },
    methods:
    {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
        getTimeMessage()
        {
          RealTime.TimeMessage().
            then(x => {
              this.time = x.data;
              this.getCountNotResponse();
              setInterval(()=>{this.getCountNotResponse()}, this.time); })
        },
        getCountNotResponse()
        {
          RealTime.NotResponseMessages().
            then(r => {

              this.notificate(r.data);
              this.messages = r.data;
              // console.log(this.messages)
              })
        },
        notificate(messagesx)
        {
          if (messagesx<this.messages)
          {
            const sound =  new Audio( require('@/assets/beep.wav'));
            this.$toast.add({severity:'success', summary:"Han sido respondidas consultas.", life: 5000});
            sound.play();
          }
          if (messagesx>this.messages)
          {
            const sound =  new Audio( require('@/assets/beep.wav'));
            this.$toast.add({severity:'success', summary:"Han llegado nuevas consultas.", life: 5000});
            sound.play();
          }


        }
    }

}
</script>
<style lang="scss">
.layout-topbar-icons {
  display: inline-block !important;
}

  .icon-button {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #333333;
  background: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;

  float:left;
  margin-left: 8px;
}

.icon-button:hover {
  cursor: pointer;
}

.icon-button:active {
  background: #cccccc;
}

.icon-button__badge {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
</style>
