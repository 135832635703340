<template>
	<Dialog :visible.sync="showModal" :style="{width: '400px'}" ref="dialog" @hide="$emit('hide')"  :modal="true" header="Recuperar contraseña" >
		<validation-observer ref="observer" v-slot="{ handleSubmit }">
			<b-form @submit.stop.prevent="handleSubmit(onSubmit)" ref="form" name="form">
				<div class="p-cardialog-content">
					<b-container class="py-4">
						<b-row class="mb-3">
							<b-col>
								<div class="form-group ">
									<ValidationProvider name="correo" :rules="{ required: true, email: true}"
														v-slot="validationContext" >
										<div class="p-float-label">
											<InputText id="title" type="email" v-model="model.email"  class="w-100"/>
											<b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>
											<label for="title">Correo</label>

										</div>

									</ValidationProvider>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>


			</b-form>
		</validation-observer>
		<template #footer>
			<Button :label="!is_loading ? 'Enviar':'Enviando'" :icon="is_loading ? 'pi pi-spin pi-spinner' :'pi pi-check'"  class="p-button-success" @click="onSubmit()" :disabled="is_loading"   />
		</template>
	</Dialog>
</template>

<script>


	import Dialog from 'primevue/dialog';
	import Auth from "../../service/Auth";
	export default {
		props:{
			showForm:{
				type: Boolean,
				required: true
			}
		},
		mounted() {
		},
		data() {
			return {
				is_loading:false,
				showModal: false,
				model:{
					email: ''
				}
			}
		},
		components:{
			Dialog
		},
		methods :{
			onSubmit() {
				this.is_loading = true;
				Auth.sendRecovery(this.model).then(()=>{this.is_loading = false; this.$emit('hide');
				this.$toast.add({severity:'success', summary:"Por favor verifique su bandeja de correo", life: 3000});
				}, ()=>{this.is_loading = false;})
			}
		},
		watch:{
			showForm(newVal){
				this.showModal = newVal;
			}
		}
	}
</script>


