export const routesNames = {
  home:
  {
    path:'/',
    name:'home'
  },
  consultations:
  {
    path:'/consultations',
    name:'consultations'
  },
  consultationTypes:
  {
    path:'/consultation-types',
    name:'consultation-types'
  },
  massNotifications:
  {
    path:'/mass-notifications',
    name:'mass-notifications'
  },
  personal:
  {
    path:'/personal',
    name:'personal'
  },
  AppUser:
  {
    path:'/AppUser',
    name:'AppUser'
  },
  AdminAppUsers:
  {
    path:'/AdminAppUsers',
    name:'AdminAppUsers'
  },
  BackOficeUser:
  {
    path:'/BackOficeUser',
    name:'BackOficeUser'
  },
  paymentTickers:
  {
    path: '/payment-tickets/:userId',
    name: 'payment-tickers',
  },
  configs:{
    path: '/configs',
    name: 'configs',
  },
  changePassword:{
    path: '/change-password/:token',
    name: 'change-password',
  },
  route403:{
    path: '/403',
    name: '403',
  }
};

