import auth from './auth.module'

export default {
    modules: {
        auth:auth
    },
    state: {
        user: null
    },
    mutations: {},
    actions:{
        responseError(){}
    },
    getters: state => {
        return state.auth
    }
};
