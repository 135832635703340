<template>
	<div class="main" >
		<b-container class="my-auto">
			<b-row align-h="center">
				<b-col lg="6">
					<Panel header="Cambio de clave" class="m-auto ">
						<validation-observer ref="observer" v-slot="{ handleSubmit }">
							<b-form @submit.stop.prevent="handleSubmit(onSubmit)">
								<div class="m-auto card-body">
									<b-container>
										<b-row class="pb-3">
											<b-col >
												<div class="form-group">
													<ValidationProvider name="correo" :rules="{ required: true, email: true}"
																		v-slot="validationContext" >
														<div class="p-float-label">
															<InputText id="email" type="email" v-model="model.email"  class="w-100"/>
															<b-form-invalid-feedback    :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>
															<label for="email">Correo</label>

														</div>
													</ValidationProvider>
												</div>
											</b-col>
										</b-row>
										<b-row class="pb-3">
											<b-col >
												<div class="form-group">
													<ValidationProvider name="clave" :rules="{ required: true , min:10, alpha_num:true, confirmed:'confirmed'}" v-slot="validationContext" >
														<div class="p-float-label">
															<InputText id="password" type="password" v-model="model.password" class="w-100" />
															<b-form-invalid-feedback   :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

															<label for="password">Nueva contraseña</label>
														</div>
													</ValidationProvider>
												</div>
											</b-col>
										</b-row>
										<b-row class="pb-3">

											<b-col >
												<div class="form-group">
													<ValidationProvider name="clave" :rules="{ required: true , min:10, alpha_num:true}" v-slot="validationContext"  vid="confirmed">
														<div class="p-float-label">
															<InputText id="confirmation" type="password" v-model="model.password_confirmation" class="w-100" />
															<b-form-invalid-feedback   :state="true" :class="{'d-block':true}">{{ validationContext.errors.join(',')}}</b-form-invalid-feedback>

															<label for="password">Repita contraseña</label>
														</div>
													</ValidationProvider>
												</div>
											</b-col>
										</b-row>
										<b-row>
											<b-col class="text-right">
												<Button label="Enviar" class="p-button-raised p-button-rounded btn-company" :disabled="is_loading" />
											</b-col>
										</b-row>
									</b-container>
								</div>
							</b-form>
						</validation-observer>
					</Panel>
				</b-col>
			</b-row>
		</b-container>

	</div>
</template>

<script>

	import InputText from 'primevue/inputtext';
	import Panel from 'primevue/panel';
	import { BFormInvalidFeedback } from 'bootstrap-vue'
	import Auth from "../../service/Auth";

	export default {
		data() {
			return {
				v:{
					confirmation:'',
					value:'',
				},
				is_loading:false,

				model:{
					email: '',
					password:'',
					password_confirmation:''
				}
			}
		},
		components:{
			InputText,
			Panel,
			BFormInvalidFeedback
		},
		methods :{
			onSubmit() {
				this.is_loading = true;
				const send = Object.assign({},this.model, this.$route.params);
				Auth.changePassword(this.$route.params.token, send).then(()=> {
					this.is_loading = false;
					this.$toast.add({severity:'success', summary:"Cambio de clave exitoso por favor inicia ingrese", life: 3000});
					this.$router.push({name:'home'});

				}, ()=> this.is_loading = false);

			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../assets/layout/variables";
	.main{
		width: 100vw;
		height: 100vh;
		display: flex;
	}

</style>
