import Vue from 'vue';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Column from 'primevue/column';
import ToastService from 'primevue/toastservice';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Panel from 'primevue/panel';

import 'primevue/resources/themes/nova-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import '../assets/layout/layout.scss';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Toast from 'primevue/toast';
import 'moment/locale/es'  // without this line it didn't work
import moment from 'moment'
moment.locale('es')

Vue.use(VueAxios, axios)


// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(ToastService);

moment.locale('es')
Vue.use(require('vue-moment'), {
    moment
})

// console.log(Vue.moment().locale())

Vue.component('Accordion', Accordion);
Vue.component('AccordionTab', AccordionTab);
Vue.component('Button', Button);
Vue.component('Column', Column);
Vue.component('DataTable', DataTable);
Vue.component('DataView', DataView);
Vue.component('Dropdown', Dropdown);
Vue.component('InputText', InputText);
Vue.component('MultiSelect', MultiSelect);
Vue.component('DataViewLayoutOptions', DataViewLayoutOptions);
Vue.component('Panel', Panel);
Vue.component('Toast', Toast);

