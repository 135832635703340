import Vue from 'vue';
import App from './App.vue';
import Vuex from 'vuex'
import store from './store';
import  './validate';
import  './components';
import router from './router';
import './service'
import { firestorePlugin } from 'vuefire';


Vue.use(Vuex);
Vue.use(firestorePlugin);
Vue.config.productionTip = false;


const  vuex = new Vuex.Store(store);
window.vuex = vuex;
window.router = router;
window.vm = new Vue({
	router,
	store: vuex,
	render: h => h(App),
}).$mount('#app');
