import utils from "../../service/Utils";
import {routesNames} from '../../constants/routes'


export default (to, from, next) => {
    if (to.name !== 'Login' && !window.vuex.state.auth.user){
        return  next({ name: 'login' })
    }

    if (to.name == routesNames.home.name)
    {
      return next();
    }
    if (utils.allowMenu(to.name))
      return next();
    else
      return next({name:routesNames.route403.name});

}
