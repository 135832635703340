<template>
    <div>
        <div v-if="visible==true">
            <loading-Component ></loading-Component>
        </div>


        <Main v-if="$store.getters.isAuthenticate" ></Main>
        <ChangePassword v-else-if="$route.name ==='change-password'"></ChangePassword>
        <Login v-else></Login>
        <Toast />

    </div>

</template>

<script>

    import Login from  './modules/login/Login';
    import ChangePassword from  './modules/ChangePassword/ChangePassword';
    import Main from  './modules/main/App';
    import {LoadingService} from './service/layouts/loading';
    import loadingComponent from './components/layout/loading/loading'
    export default {
        components:{Login, Main, ChangePassword, loadingComponent},
        data() {
            return {
                visible:false
            }
        },

        created() {
            // console.log(this.$route.name);
            this.subscription = LoadingService.getMessage().subscribe(message => {
                let visibilidad = String(message.text);
                // console.log(visibilidad)
                if (visibilidad.localeCompare("visible") ==0) {
                    // console.log("soy visible")
                    this.visible = true;
                } else {
                    // console.log("soy in visible")
                    this.visible = false;
                }
        });

        },
        mounted() {
            this.$store.subscribeAction((action) => {
                if(action.type === 'responseError') {
                    this.$toast.add({severity:'error', summary:'Error', detail: action.payload.message, life: 3000});

                }
            })
        }

    }
</script>

<style lang="scss">
    @import './App.scss';


</style>
