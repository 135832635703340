<template src='./loading.html'>
</template>

<style scoped src="./loading.css">
</style> 

<script>
export default {
    
}
</script>