import Vue from 'vue';
import Router from 'vue-router';
import isAuthenticate from  './guards/onlyAuthenticate';
import {routesNames} from '../constants/routes'

Vue.use(Router);

export default new Router({
	routes: [
		{
			path: routesNames.home.path,
			name: routesNames.home.name,
			component: () => import('../modules/dashboard/DashboardIndex'),
			beforeEnter: isAuthenticate,
			children: [

			]
		},
		{
			path: routesNames.consultations.path,
			name: routesNames.consultations.name,
			component: () => import('../modules/consultation/ConsultationsIndex'),
			beforeEnter: isAuthenticate
		},
		{
			path: routesNames.consultationTypes.path,
			name: routesNames.consultationTypes.name,
			component: () => import('../modules/consultationTypes/ConsultationTypesIndex'),
			beforeEnter: isAuthenticate
		},
		{
			path: routesNames.massNotifications.path,
			name: routesNames.massNotifications.name,
			component: () => import('../modules/massNotifications/MassNotificationsIndex'),
			beforeEnter: isAuthenticate
		},
		{
			path: routesNames.personal.path,
			name: routesNames.personal.name,
			component: () => import('../modules/personal/PersonalIndex'),
			beforeEnter: isAuthenticate
    },
    {
			path:  routesNames.AppUser.path,
			name: routesNames.AppUser.path,
			component: () => import('../modules/AppUsers/AppUsersIndex'),
			beforeEnter: isAuthenticate
		},
		{
			path: routesNames.AdminAppUsers.path,
			name: routesNames.AdminAppUsers.name,
			component: () => import('../modules/AdminAppUsers/AdminAppUsers'),
			beforeEnter: isAuthenticate
		},
		{
			path: routesNames.BackOficeUser.path,
			name: routesNames.BackOficeUser.name,
			component: () => import('../modules/BackOfficeUsers/BackOficeUserTable'),
			beforeEnter: isAuthenticate
		},
		{
			path: routesNames.paymentTickers.path,
			name: routesNames.paymentTickers.name,
			component: () => import('../modules/paymentsTickets/PaymentTicketsIndex'),
			beforeEnter: isAuthenticate
		},
		{
			path: routesNames.configs.path,
			name: routesNames.configs.name,
			component: () => import('../modules/Configs/Configs'),
			beforeEnter: isAuthenticate
		},
		{
			path: routesNames.changePassword.path,
			name: routesNames.changePassword.name,
			component: () => import('../modules/ChangePassword/ChangePassword')
		},
    {
			path: routesNames.route403.path,
			name: routesNames.route403.name,
			component: () => import('../modules/Permisssion/403')
		}
	],
	scrollBehavior() {
		return {x: 0, y: 0};
	}
});
