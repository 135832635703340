<template>
	<div :class="containerClass" @click="onWrapperClick">
		<AppTopBar @menu-toggle="onMenuToggle" />

        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <div class="layout-logo">
                    <router-link to="/">
                        <img alt="Logo" :src="logo" />
                    </router-link>
                </div>

                <AppProfile />
                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
            </div>
        </transition>

		<div class="layout-main">
			<router-view />
		</div>


	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppProfile from './AppProfile.vue';
import AppMenu from './AppMenu.vue';
import Auth from '../../service/Auth';
import utils,{MenuLoadedService} from '../../service/Utils'
  export default {
    data() {
        return {
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menuXUser:[],
            menu :  [
                {label: 'dashboard', icon: 'pi pi-fw pi-home', to: '/'},
                {
                    label: 'Menu Modes', icon: 'pi pi-fw pi-cog',
                    items: [
                        {label: 'Static Menu', icon: 'pi pi-fw pi-bars',  command: () => this.layoutMode = 'static' },
                        {label: 'Overlay Menu', icon: 'pi pi-fw pi-bars',  command: () => this.layoutMode = 'overlay' }
                    ]
                },
                //{label: 'Gestionar Usuarios App', icon: 'pi pi-fw pi-circle-off', to: '/AppUser'},
                // {label: 'Admitir usuarios App', icon: 'pi pi-fw pi-circle-off', to: '/uploadFilesRegister'},
                // {label: 'Usuarios Backoffice', icon: 'pi pi-fw pi-circle-off', to: '/BackOficeUser'},
                // {label: 'Gestión de Consultas', icon: 'pi pi-fw pi-circle-off', to: '/consultations'},
                // {label: 'Tipos de consultas', icon: 'pi pi-fw pi-circle-off', to: '/consultation-types'},
                // {label: 'Notificaciones masivas', icon: 'pi pi-fw pi-circle-off', to: '/mass-notifications'},
                // {label: 'Config', icon: 'pi pi-fw pi-circle-off', to: '/configs'}
            ]
        }
    },
    created: function ()
    {
      if (this.menuXUser.length == 0)
      {
        Auth.getMenu()
          .then (me => {
            // console.log(me.data)
            this.menuXUser = me.data.filter(m => {return m.visible == true});
            //console.log(this.menuXUser)
            MenuLoadedService.Notificate(true);
            utils.setlocalMenu(me.data);
            //// console.log('tengo el menu user',this.menuXUser);
            this.menu = this.menu.concat (this.menuXUser);
            //// console.log('tengo el menu',this.menu);
          });
      }

    },
    watch: {
        $route() {
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    this.overlayMenuActive = !this.overlayMenuActive;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        },
        logo() {
            return require('../../assets/logo.png');
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu
     }
}
</script>

<style lang="scss">
</style>
